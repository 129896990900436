<template>
    <div class="container-fluid">



        <div class="row">




            <div v-for="(question, index) in questions" :key="index" class=" col-md-6 col-sm-12">

                <div class="card">
                    <div v-if="question && question.pieChart" class="card-body">


                        <p class="fw-bold">{{ question.question }} ?</p>

                        <apexchart class="apex-charts" height="320" type="pie" dir="ltr"
                            :series="question.pieChart.series" :options="question.pieChart.chartOptions"></apexchart>
                    </div>
                </div>

            </div>



        </div>


    </div>



</template>

<script>
import { pieChart } from '../../chartData'
export default {
    data() {
        return {
            questions: [],
            pieChart: pieChart
        }
    },

    components: {

    },

    methods: {
        getReandomHex() {
            let arr = [];
            let suggestions = "0123456789ABCDEF";
            for (let i = 0; i < 6; i++)
                arr.push(suggestions[Math.floor(Math.random() * suggestions.length)]);
            return "#" + arr.join("");
        },

        getMarketingSurveyQuestionWithChartsBySurveyId() {
            this.http.post('marketing-survey-questions/by-survey-id', {
                marketing_survey_id: this.$route.params?.id,
            }).then((res) => {

                this.questions = res.data

                this.questions = this.questions.map((item) => {

                    if (item.marketing_survey_question_type.name == 'check_box') {

                        item.pieChart = JSON.parse(JSON.stringify(this.pieChart))
                        item.pieChart.series = item.series
                        item.pieChart.chartOptions.labels = item.options
                        item.pieChart.chartOptions.colors = item.options.map(() => this.getReandomHex())
                    }

                    else {

                        item.pieChart = JSON.parse(JSON.stringify(this.pieChart))
                        console.log("step 89 : item label options", item.options);
                        item.pieChart.series = item.series
                        item.pieChart.chartOptions.labels = item.options
                        item.pieChart.chartOptions.colors = item.options.map(() => this.getReandomHex())

                    }
                    return item
                }).filter(i => i != undefined)
                console.log('items :', this.questions)
            })

        }

    },

    mounted() {
        this.getMarketingSurveyQuestionWithChartsBySurveyId()
    },
}
</script>

<style></style>