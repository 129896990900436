<script>
import appConfig from "@/app.config";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      data: {},
      SurveyQuestions: [],
      archive_files_types: [],
      question_types: [],
      addOptions: [{}],
      selectedQ: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchArchiveFiles: null,
      ArchiveFilesSearchMode: false,
      searchModel: "",

    };
  },
  methods: {
    getQuestionsTypes() {
      this.http.get("marketing-survey-question-types", null, null, true).then((res) => {
        this.question_types = res.data;
      });
    },
    addMarketingSurveyQuestion() {
      this.data.marketing_survey_id = this.$route.params?.id;
      this.data.question_options = this.addOptions;
      console.log({ data: this.data });
      this.http.post("marketing-survey-questions", this.data, null, true).then((res) => {
        if (res.status) {
          document.querySelector('#addQuestionModal .close-modal-btn').click()
          this.get(this.page);
          this.addOptions = [{}]
          this.data = {}
        }
      });
    },
    editMarketingSurveyQuestion() {
      this.selectedQ.marketing_survey_id = this.$route.params?.id;
      this.selectedQ.marketing_survey_question_options.forEach(
        (i) => (i.marketing_survey_question_id = this.selectedQ.id)
      );
      console.log({ selected: this.selectedQ });
      this.http
        .post("marketing-survey-questions/update", this.selectedQ, null, true)
        .then((res) => {
          if (res.status) {
            this.get(this.page);
            document.querySelector('#editQModal .close-modal-btn').click()
            this.popup.alert({ title: '', msg: 'popups.success' })

          }
        });
    },
    search() {
      this.ArchiveFilesSearchMode = true;
      this.tot_pages = 0;
      this.http
        .post("marketing-survey-questions/by-survey-id/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          col: "question",
          marketing_survey_id: this.$route.params?.id,

        })
        .then((res) => {
          this.SurveyQuestions = res.data;
          console.log("######## files", this.SurveyQuestions);
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.ArchiveFilesSearchMode = false;
      this.get(this.page);
    },
    deleteEditedOption(option) {
      this.selectedQ.marketing_survey_question_options =
        this.selectedQ.marketing_survey_question_options.filter(
          (i) => i != option
        );
    },
    deleteAddOption(option) {
      this.addOptions =
        this.addOptions.filter(
          (i) => i != option
        );
    },

    deleteMarketingSurveyQ(app) {
      var data = {
        title: "popups.deletefile",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("marketing-survey-questions", app.id).then(() => {
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },
    setSelectedQ(q) {
      this.selectedQ = JSON.parse(JSON.stringify(q));

    },
    get(page) {
      console.log(page);
      this.http
        .post("marketing-survey-questions/by-survey-id/pagination", {
          limit: this.limit,
          page: page,
          marketing_survey_id: this.$route.params?.id,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          console.log({ returnedDataImportant: res.data });
          this.SurveyQuestions = res.data;
        });
    },
  },
  created() {
    this.getQuestionsTypes();
    this.get(1);
  },
};
</script>

<template>
  <div class="row align-items-center">
    <div class="col-4">
      <!-- <div>
        <h5 class="mb-0">
          {{ $t("menu.menuitems.marketing.tabs.marketing_areas") }}
        </h5>
      </div> -->
    </div>

    <div class="col-8">
      <div class="search-box chat-search-box d-flex align-items-center" style="width: 250px; margin-inline-start: auto">
        <div class="position-relative mx-3 d-flex align-items-center">
          <input @keyup="search()" type="text" class="form-control fa-lg text-light"
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''" :placeholder="$t('users.searchplaceholder')" v-model="searchModel"
            style="background-color: #2a3042 !important" />
          <i class="bx bx-search-alt search-icon text-light"></i>
          <button @click="cancelappsearchMode()" v-if="searchModel" class="btn-close text-light"
            style="position: absolute; top: 12px; left: 250px"></button>
        </div>
        <button type="button" class="btn btn-light float-end" data-bs-toggle="modal" data-bs-target="#addQuestionModal">
          <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
        </button>
      </div>
    </div>
  </div>
  <!-- end row -->

  <hr class="mb-4" />

  <div class="row">
    <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
      <thead>
        <tr class="text-light text-center" style="background-color: #2a3042 !important">
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.id") }}
          </th>
          <th scope="col">
            {{ $t("marketing.questions.table.name") }}
          </th>
          <th scope="col">
            {{ $t("marketing.questions.table.type") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.created") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.updated") }}
          </th>
          <th scope="col">
            {{ $t("archiving_documents.archiving_documents_table.operations") }}
          </th>
          "
        </tr>
      </thead>
      <tbody>
        <tr v-for="(app, i) in SurveyQuestions" :key="app" class="text-center">
          <td>{{ i + 1 }}</td>
          <td>{{ app?.question }}</td>
          <td>{{ app?.marketing_survey_question_type?.name }}</td>
          <td>{{ app?.created.split("T")[0] }}</td>
          <td>{{ app?.updated.split("T")[0] }}</td>

          <td class="d-flex justify-content-end">
            <a class="btn btn-primary mx-1" @click="setSelectedQ(app)" href="javascript: void(0);" role="button"
              data-bs-target="#editQModal" data-bs-toggle="modal">{{ $t("popups.edit") }}</a>
            <a class="btn btn-danger" href="javascript: void(0);" role="button" @click="deleteMarketingSurveyQ(app)">{{
              $t("popups.delete") }}</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <div v-if="tot_pages == 20">
    <!--   Apps  Pagination     -->
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a class="page-link" href="javascript: void(0);" @click="get(page - 1)" aria-label="Previous">
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{ active: p == page }" v-for="p in tot_pages" :key="p">
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
              p
            }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a class="page-link" href="javascript: void(0);" @click="get(page + 1)" aria-label="Next">
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <!--end-->

  <!--Start Add Modal-->
  <div class="modal fade" id="addQuestionModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="addMarketingSurveyQuestion()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.adduser") }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              style="margin: 0"></button>
          </div>

          <div class="modal-body">
            <label for="question">{{ $t("marketing.question") }}</label>
            <input v-model="data.question" id="question" required class="form-control" type="text"
              :placeholder="$t('marketing.question')" />

            <label for="question_type">{{ $t("marketing.question_type") }}</label>
            <select v-model="data.marketing_survey_question_type_id" class="form-select mb-2" id="question_type"
              required>
              <option v-for="qType in question_types" :key="qType.id" :value="qType.id">
                {{ qType?.name }}
              </option>
            </select>

            <!-- question options -->
            <div class="row d-flex align-items-center">
              <div class="col">
                <label for="user_role">{{ $t("marketing.question_options") }}</label>
              </div>
              <div class="col d-flex justify-content-end">
                <button type="button" @click="addOptions.push({})" class="btn float-end">
                  <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
                </button>
              </div>
            </div>
            <div v-for="option in addOptions" :key="option">
              <div class="border border-2 px-4 pt-5 pb-4 my-4" style="border-radius: 0.25rem; position: relative">
                <!-- close icon -->
                <div class="d-none d-lg-inline-block ms-1" style="position: absolute; right: -0rem; top: -0.8rem"
                  @click="deleteAddOption(option)" v-if="addOptions.length > 1">
                  <button type="button" class="btn header-item noti-icon" @click="logout">
                    <i class="bx bx-trash text-danger fa-2xl"></i>
                  </button>
                </div>
                <input v-model="option.option" :id="`option_${option}`" required class="form-control" type="text"
                  :placeholder="$t('marketing.add_option')" />
              </div>
            </div>
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.add") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn close-modal-btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Modal-->

  <!--Edit Modal-->
  <div class="modal fade" id="editQModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''">
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="editMarketingSurveyQuestion()">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.resetpass") }}
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
              style="margin: 0"></button>
          </div>

          <div class="modal-body">
            <label for="question">{{ $t("marketing.question") }}</label>
            <input v-model="selectedQ.question" id="edit_question" required class="form-control" type="text"
              :placeholder="$t('marketing.question')" />

            <label for="question_type">{{ $t("marketing.question_type") }}</label>
            <select v-model="selectedQ.marketing_survey_question_type_id" class="form-select mb-2"
              id="edit_question_type" required>
              <option v-for="qType in question_types" :key="qType.id" :value="qType.id">
                {{ qType?.name }}
              </option>
            </select>

            <!-- question options -->
            <div class="row d-flex align-items-center">
              <div class="col">
                <label for="user_role">{{ $t("marketing.question_options") }}</label>
              </div>
              <div class="col d-flex justify-content-end">
                <button type="button" @click="selectedQ.marketing_survey_question_options.push({})"
                  class="btn float-end">
                  <span class="bx bxs-plus-circle float-end fa-2x" style="color: #2a3042 !important"></span>
                </button>
              </div>
            </div>
            <div v-for="option in selectedQ.marketing_survey_question_options" :key="option">
              <div class="border border-2 px-4 pt-5 pb-4 my-4" style="border-radius: 0.25rem; position: relative">
                <!-- close icon -->
                <div class="d-none d-lg-inline-block  ms-1" style="position: absolute; right: -0rem; top: -0.8rem"
                  @click="deleteEditedOption(option)" v-if="selectedQ.marketing_survey_question_options.length > 1">
                  <button type="button" class="btn header-item noti-icon" @click="logout">
                    <i class="bx bx-trash text-danger fa-2xl"></i>
                  </button>
                </div>
                <input v-model="option.option" :id="`option_${option.id}`" required class="form-control" type="text"
                  :placeholder="$t('marketing.option')" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">
              {{ $t("popups.edit") }}
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn close-modal-btn btn-danger">
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--Edit pass Modal-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
